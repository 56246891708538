/* eslint no-console: 0 */

import { Injectable } from "@angular/core";
import { environment } from "app/environments/environment";
import * as Sentry from "@sentry/browser";

if (environment.enableSentry && environment.production) {

  Sentry.init({
    dsn: "https://ccff09193bae47d6afe18ccf6b6df3ad@sentry3.vnc.biz/3",
    release: "web-4.0.8.30214",
    maxBreadcrumbs: 150,
    debug: true,
  });
}

export const isDebugMode = environment.isDebugMode || !!localStorage.getItem("isDebugMode");
const noop = (): any => undefined;

export abstract class Logger {
  info: any;
  warn: any;
  error: any;
}

@Injectable()
export class LoggerService implements Logger {
  constructor() {
    this.info("[LoggerService][environment]", environment);
  }

  static info(...agrs) {
    if (isDebugMode) {
      return console.info(agrs);
    }
  }

  static error(...agrs) {
    if (isDebugMode) {
      return console.error(agrs);
    }
  }

  get info() {
    if (isDebugMode) {
      return console.info.bind(console);
    } else {
      return noop;
    }
  }

  get warn() {
    if (isDebugMode) {
      return console.warn.bind(console);
    } else {
      return noop;
    }
  }

  get error() {
    if (isDebugMode) {
      return console.error.bind(console);
    } else {
      return noop;
    }
  }

  sentryLog(text: string) {
    if (!!localStorage.getItem("userJID")) {
      const jid = localStorage.getItem("userJID");
      text = `[JID]${jid} - [MESSAGE] ${text}`;
    }
    if (environment.isCordova && environment.enableSentry && environment.production) {
      try {
        Sentry.captureMessage(text);
      } catch (ex) {
        this.error(ex);
      }
    } else {
      this.info(text);
    }
  }

  configureScope(jid) {
    Sentry.configureScope(scope => {
      scope.setUser({ id: jid, email: jid, username: jid });
    });
  }

  sentryErrorLog(...agrs: any[]) {
    let text = "";
    agrs.forEach(v => {
      if (typeof v === "object") {
        try {
          text += " === " + JSON.stringify(v);
        } catch (error) {
          this.error("Cannot parse object", error);
        }
      } else {
        text += " === " + v;
      }
    });
    if (!!localStorage.getItem("userJID")) {
      const jid = localStorage.getItem("userJID");
      text = `ERROR: [JID]${jid} - [MESSAGE] ${text}`;
    }
    if (environment.enableSentry && environment.production) {
      try {
        Sentry.captureMessage(text);
      } catch (ex) {
        this.error(ex);
      }
    }
  }

  captureException(error) {
    if (environment.enableSentry && environment.production) {
      try {
        Sentry.captureException(error);
      } catch (ex) {
        this.error(ex);
      }
    }
  }

  invokeConsoleMethod(type: string, args?: any): void {
    const logFn: Function = (console)[type] || console.log || noop;
    logFn.apply(console, [args]);
  }
}
